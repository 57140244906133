import React from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Fab, Tooltip } from "@material-ui/core";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import StopScreenShareIcon from "@material-ui/icons/StopScreenShare";
import DeviceSelector from "../../DeviceSelector";
import PlaceholderVideo from "./PlaceholderVideo";
import ScreenInitial from "./Initial";
import Participant from "../../Participant/Participant";
import ApplicationLanguage from "../../ApplicationLanguage";
import ReplayIcon from "@material-ui/icons/Replay";
import Clock from "../../Clock";

const useStyles = makeStyles((theme) => ({
  participant: {
    margin: 20,
    [theme.breakpoints.down("md")]: {
      margin: 10,
    },
  },
  button: {
    padding: 20,
    fontSize: "large",
    margin: 30,
    width: 240,
    zIndex: 9,
    [theme.breakpoints.down("md")]: {
      margin: 10,
    },
  },
  alignText: {
    textAlign: "center",
  },
  headerText: {
    margin: "1rem 0 0.5rem 0",
    textAlign: "center",
  },
  shareFab: {
    opacity: 1,
    position: "absolute",
    right: theme.spacing(4),
    bottom: theme.spacing(25),
    [theme.breakpoints.down("lg")]: {
      right: theme.spacing(2),
      bottom: theme.spacing(25),
    },
    [theme.breakpoints.down("md")]: {
      opacity: 0.7,
      position: "fixed",
      right: theme.spacing(2),
      bottom: theme.spacing(18),
    },
  },
  participantFab: {
    opacity: 1,
    position: "absolute",
    zIndex: 9,
    right: theme.spacing(4),
    bottom: theme.spacing(15),
    [theme.breakpoints.down("lg")]: {
      right: theme.spacing(2),
      bottom: theme.spacing(15),
    },
    [theme.breakpoints.down("md")]: {
      opacity: 0.7,
      position: "fixed",
      right: theme.spacing(2),
      bottom: theme.spacing(10),
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "16%",
  },
  langSelector: {
    display: "flex",
    alignItems: "center",
  },
  btnReload: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: "white",
    zIndex: 16923,
  },
  err: {
    padding: theme.spacing(2),
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Home = (props) => {
  const classes = useStyles();

  const {
    helper,
    headerText,
    username,
    endTime,
    startTime,
    onButtonClick,
    loading,
    stepsMessages,
    step,
    room,
    participantJoined,
    errorMsg,
    errorText,
    cameraOn,
    i18ntext,
    setI18nText,
    Chat,
    onShareScreen,
    sharing,
    noSharing,
    onShowDescription,
  } = props;

  let remoteParticipants = [];

  if (room) {
    if (room.participants) {
      //Get all remote participants
      room.participants.forEach((participant) => {
        let sharingScreen = false;

        participant.tracks.forEach((track) => {
          if (track.trackName === "screenShare") {
            sharingScreen = true;
          }
        });

        remoteParticipants.push(
          <Participant
            key={participant.sid}
            participant={participant}
            sharingScreen={sharingScreen}
          />
        );
      });
    }
  }
  const reload = () => {
    window.location.reload();
  };
  return (
    <>
      {!participantJoined && !cameraOn ? <ScreenInitial /> : null}

      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item container className={classes.alignText}>
          <Grid item md={10} xs={9} className={classes.details}>
            <h2 className={classes.headerText}>{headerText}</h2>
            <Clock
              endTime={endTime}
              startTime={startTime}
              i18nText={i18ntext}
            />
            {errorMsg ? (
              <Button
                className={classes.btnReload}
                variant={"contained"}
                color={"primary"}
                onClick={reload}
              >
                <ReplayIcon />
              </Button>
            ) : (
              <Button
                className={classes.button}
                size={"large"}
                variant={"contained"}
                color="primary"
                onClick={onButtonClick}
                disabled={loading || props.expired}
              >
                {stepsMessages[step]}
              </Button>
            )}

            {room && !participantJoined && (
              <p className="warning">{i18ntext.waitForPeer}</p>
            )}
            {errorMsg && errorText()}
            {!room && <DeviceSelector />}
          </Grid>
          <Grid item md={2} xs={3} className={classes.langSelector}>
            <ApplicationLanguage i18nText={i18ntext} handleLang={setI18nText} />
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="space-evenly">
          <Grid item className={classes.participant}>
            {room ? (
              <Participant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                sharingScreen={false}
              />
            ) : (
              <PlaceholderVideo />
            )}
          </Grid>

          <Grid item className={classes.participant}>
            {remoteParticipants.length > 0 ? (
              <div>{remoteParticipants}</div>
            ) : (
              <PlaceholderVideo />
            )}
          </Grid>
        </Grid>

        {/*IT support text*/}
        {helper}

        <Chat
          username={username}
          chatPlaceholder={i18ntext.chatPlaceholder}
          tooltipText={i18ntext.tooltip.chat}
        />

        {!noSharing && (
          <Tooltip
            title={i18ntext.tooltip.screenShare}
            aria-label="share"
            placement="left-start"
          >
            <Fab
              className={classes.shareFab}
              onClick={onShareScreen}
              fontSize="large"
              id="share-fab"
            >
              {sharing ? (
                <StopScreenShareIcon fontSize="large" color="primary" />
              ) : (
                <ScreenShareIcon fontSize="large" color="primary" />
              )}
            </Fab>
          </Tooltip>
        )}

        <Tooltip
          title={i18ntext.roomDescriptionText}
          aria-label="show-participants"
          placement="left-start"
        >
          <Fab
            className={classes.participantFab}
            onClick={onShowDescription}
            fontSize="large"
            id="show-fab"
          >
            <DescriptionIcon fontSize="large" color="primary" />
          </Fab>
        </Tooltip>
      </Grid>
    </>
  );
};

export default Home;
