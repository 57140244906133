import React, {useState, useEffect, useRef} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {DEVICE_KEY} from "../DeviceSelector";

const useStyles = makeStyles((theme) => ({
    videoBox: {
        "& video": {
            maxWidth: "100%",
            borderRadius: 12,
            background: 'black',
            [theme.breakpoints.down('xl')]: {
                width: 780,
                height: 585,
            },
            [theme.breakpoints.down('lg')]: {
                width: 600,
                height: 450,
            },
            [theme.breakpoints.down('md')]: {
                width: 500,
                height: 375,
            },
            [theme.breakpoints.down('sm')]: {
                width: 400,
                height: 300,
            },
            [theme.breakpoints.down('xs')]: {
                width: 300,
                height: 225,
            }
        },
    },
}));

const Participant = ({participant, sharingScreen}) => {
    const [videoTracks, setVideoTracks] = useState([]);
    const [audioTracks, setAudioTracks] = useState([]);

    const videoRef = useRef();
    const audioRef = useRef();

    const classes = useStyles();

    const trackpubsToTracks = (trackMap) =>
        Array.from(trackMap.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null);

    useEffect(() => {
        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.audioTracks));

        const trackSubscribed = (track) => {
            if (track.kind === "video") {
                setVideoTracks((videoTracks) => [...videoTracks, track]);
            } else if (track.kind === "audio") {
                setAudioTracks((audioTracks) => [...audioTracks, track]);
            }
        };

        const trackUnsubscribed = (track) => {
            if (track.kind === "video") {
                setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
            } else if (track.kind === "audio") {
                setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
            }
        };

        participant.on("trackSubscribed", trackSubscribed);
        participant.on("trackUnsubscribed", trackUnsubscribed);

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
        };
    }, [participant]);

    useEffect(() => {
        const videoTrack = videoTracks[0];
        if (videoTrack) {
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            const node = audioRef.current
            audioTrack.attach(node);
            if (node && node.setSinkId){
                const id = localStorage.getItem(DEVICE_KEY.AUDIO_OUTPUT);
                node.setSinkId(id);
            }
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);

    return (
        <div className={classes.videoBox}>
            {/*<h3>{participant.identity}</h3>*/}
            <video id={participant.identity} ref={videoRef} controls={true} autoPlay={true}/>
            <audio ref={audioRef} autoPlay={true}/>
        </div>
    );
};

export default Participant;