import React from 'react'

import {ThemeProvider} from '@material-ui/core'

import theme from './theme'
import Room from "./components/Room/Room";

function App() {

    return (
        <ThemeProvider theme={theme}>
            <Room/>
        </ThemeProvider>
    );
}

export default App;
