import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  isLessThanMinuteAway,
  isRoomExpired,
  toFinnishLocaleDate,
} from "../../util/utils";

import Bell from "../Bell";
import { subtractMinuteFromTime } from "../../util/utils";

const useStyles = makeStyles(() => ({
  headerText: {
    margin: "0 0 0.5rem 0",
    textAlign: "center",
  },
  timeZone: {
    margin: "0 0 0.5rem 0",
    fontWeight: 100,
  },
  warning: {
    color: "#e10000",
  },
}));

interface ClockProps {
  startTime: Date;
  endTime: Date;
  i18nText: any;
}
const Clock: FC<ClockProps> = ({ endTime, i18nText, startTime }) => {
  const [currentTime, setTime] = useState(new Date());
  const [timeZone, setTimeZone] = useState("");

  const classes = useStyles();

  const updateClock = () => {
    setTime(new Date());
  };

  useEffect(() => {
    const interval = setInterval(updateClock, 2000);

    let tZ = new Date().toTimeString().slice(9);
    setTimeZone(tZ.slice(0, tZ.indexOf("(")));

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getDate = (date: Date) => {
    return (
      date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    );
  };
  return (
    <div>
      <h2 className={classes.headerText}>{toFinnishLocaleDate(currentTime)}</h2>
      <h5 className={classes.timeZone}>{timeZone}</h5>
      <div>
        {endTime &&
          i18nText.roomTimeRange +
            ": " +
            getDate(startTime) +
            " " +
            toFinnishLocaleDate(startTime) +
            " - " +
            toFinnishLocaleDate(endTime)}
        {endTime && <Bell ringTime={subtractMinuteFromTime(endTime)} />}
      </div>
      {endTime && isLessThanMinuteAway(currentTime, endTime) && (
        <h4 className={classes.warning}>{i18nText.roomExpiresMinute}</h4>
      )}
      {endTime && isRoomExpired(currentTime, endTime) && (
        <h4 className={classes.warning}>{i18nText.roomExpiredWarning}</h4>
      )}
    </div>
  );
};

export default Clock;
