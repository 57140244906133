import React, {FunctionComponent, useState} from "react";
import {Button, Fade, Menu, MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import LanguageIcon from '@material-ui/icons/Language';
import english from "../i18n/en.json"
import finnish from "../i18n/fi.json"
import swedish from "../i18n/se.json"

const useStyles = makeStyles((theme) => ({
    btn: {
        padding: 8,
        zIndex: 9,
        color: 'white'
    },
}));

interface ApplicationLanguageInterface {
    handleLang: (name: any) => void,
    i18nText: any,
}

const ApplicationLanguage: FunctionComponent<ApplicationLanguageInterface> = ({handleLang, i18nText}) => {
    const [btnText, setBtnText] = useState<string>(i18nText.lang);
    const [languageState, setLanguageState] = useState<null | HTMLElement>();
    const openState = Boolean(languageState);
    const classes = useStyles()
    const handleLanguagePopup = (event: React.MouseEvent<HTMLElement>) => {
        setLanguageState(event.currentTarget)
    }

    const handleClosePopup = () => {
        setLanguageState(null)
    }

    const handleLanguageSelector = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        const {lang} = event.currentTarget.dataset
        lang && lang === "Suomi" ? handleLang(finnish) : lang === "English" ? handleLang(english) : handleLang(swedish)
        lang && setBtnText(lang)
        handleClosePopup()
    }

    return (
        <>
            <Button
                startIcon={<LanguageIcon/>}
                color="primary"
                variant={"contained"}
                className={classes.btn}
                aria-controls="lang-menu"
                aria-haspopup="true"
                onClick={handleLanguagePopup}
            >
                {btnText}
            </Button>
            <Menu
                id="lang-menu"
                anchorEl={languageState}
                keepMounted
                open={openState}
                onClose={handleClosePopup}
                TransitionComponent={Fade}
            >
                <MenuItem data-lang="English" onClick={handleLanguageSelector}>English</MenuItem>
                <MenuItem data-lang="Suomi" onClick={handleLanguageSelector}>Suomi</MenuItem>
                <MenuItem data-lang="På svenska" onClick={handleLanguageSelector}>På svenska</MenuItem>

            </Menu>
        </>
    )

}

export default ApplicationLanguage