import React, {useEffect} from "react";
import {
    Badge,
    Fab,
    Grid,
    IconButton,
    InputBase,
    Paper, Tooltip,
    Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import ChatIcon from "@material-ui/icons/Chat";
import SendIcon from "@material-ui/icons/Send";
import "../../App.css";
import Stomp from "./Stomp";
import Moment from "react-moment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {getChatHistory} from "../../api";

const useStyles = makeStyles((theme) => ({
    fab: {
        zIndex: 9,
        opacity: 1,
        position: "absolute",
        right: theme.spacing(4),
        bottom: theme.spacing(5),
        [theme.breakpoints.down('lg')]: {
            right: theme.spacing(2),
            bottom: theme.spacing(5),
        },
        [theme.breakpoints.down("md")]: {
            opacity: 0.7,
            position: "fixed",
            right: theme.spacing(2),
            bottom: theme.spacing(2),
        },
    },
    me: {
        float: "right",
    },
    them: {
        float: "left"
    },
    submit: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
    },
    cancelIcon: {
        color: theme.color
    }
}));

const Chat = ({username, chatPlaceholder, tooltipText}) => {
    const classes = useStyles();
    const [msg, setMsg] = React.useState("");
    const [messages, setMessages] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [invisible, setInvisible] = React.useState(true);
    const [flag, setFlag] = React.useState(false);
    const [connected, setConnected] = React.useState(false);
    const [key, setKey] = React.useState(0);

    const handleSubmit = (e) => {
        e.preventDefault();
        let message = document.getElementById("form").elements["chatMessage"].value;
        if (message.length > 0) {
            setMsg(message);
            message = "";
            setKey((prev) => prev + 1);
        }
    };

    const handleToggle = (e) => {
        if (open) {
            const chat = document.getElementById("chat-box");
            const fab = document.getElementById("chat-fab");
            chat.style.display = "none";
            fab.style.display = "grid";
            setOpen(false);
            if (window.innerWidth < 800 && window.innerWidth > 250) {
                const guides = document.getElementById("guides");
                guides.style.display = "grid";
            }
        }
    };
    const handleReceiveMessage = (message) => {
        if (message.sid !== undefined) {
            setMessages([...messages, message]);
            setFlag(true);
        } else {
            setFlag(false);
        }
    };
    useEffect(() => {
        if (open) {
            const fab = document.getElementById("chat-fab");
            fab.style.display = "none";
            if (!flag) {
                setFlag(false);
                setInvisible(true);
            }
            if (flag) {
                setInvisible(false);
                setFlag(false);
            }
        }
        if (!open) {
            const chat = document.getElementById("chat-box");
            chat.style.display = "none";
            if (flag) {
                setInvisible(false);
                setFlag(false);
            }
        }
    }, [open, flag]);

    const handleOpenChat = (e) => {
        if (!open) {
            const chat = document.getElementById("chat-box");
            const fab = document.getElementById("chat-fab");
            chat.style.display = "flex";
            fab.style.display = "none";
            if (window.innerWidth < 800) {
                const guides = document.getElementById("guides");
                guides.style.display = "none";
            }
            setOpen(true);
        }
    };

    useEffect(() => {
        const getMessages = async (name) => {
            let ms = await getChatHistory(name);
            // Success
            if (ms && ms.length !== 0) {
                return messages.concat(ms);
            }
            // Error branch
            return [];
        };

        const roomName = window.location.pathname.substring(1);
        if (roomName.charAt(0) !== "") {
            getMessages(roomName).then(setMessages);
        }
    }, []);

    const scrollToBottom = () => {
        const chat = document.getElementById("chat-w");

        if (chat !== null) {
            chat.scrollTop = chat.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [open]);

    useEffect(() => {
        scrollToBottom();
    }, [messages.length]);

    return (
        <>
            <Stomp
                msg={msg}
                setConnected={setConnected}
                handleReceiveMessage={handleReceiveMessage}
                username={username}
            />

            <Tooltip title={tooltipText} placement="left-start">
                <Fab
                    onClick={handleOpenChat}
                    fontSize="large"
                    id="chat-fab"
                    className={classes.fab}
                >
                    <Badge badgeContent="" color="error" invisible={invisible}>
                        <ChatIcon fontSize="large" color="primary"/>
                    </Badge>
                </Fab>
            </Tooltip>

            <div style={{zIndex: 10000}} id="chat-box" className="chat-box">
                <div className="chat-box-header">
                    <div className="toolbar">
                        <Typography variant="h6">Chat</Typography>
                        <IconButton onClick={handleToggle} aria-label="close chat">
                            <CancelIcon id="cancel-icon" className={classes.cancelIcon}/>
                        </IconButton>
                    </div>
                </div>

                <div className="chat-box-body" id="chat-w">
                    {connected &&
                    messages.map((message) => {
                        return (
                            <Grid container key={message.sid + message.timestamp}>
                                {username !== message.name ? (
                                    <Grid
                                        className="chat-msg"
                                        container
                                        item
                                        xs={9}
                                        lg={9}
                                        direction="row"
                                        justifyContent="space-around"
                                    >
                                        <Grid
                                            item
                                            xs={2}
                                            container //jus 2021.01.29
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <AccountCircle color="primary" fontSize="large"/>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={9}
                                            container   //jus 2021.01.29
                                            direction="column"
                                            justifyContent="flex-start"
                                        >
                                            <Typography
                                                className="chat-msg-content"
                                                variant="body2"
                                            >
                          <span
                              dangerouslySetInnerHTML={{
                                  __html: message.message,
                              }}
                          />
                                            </Typography>
                                            <span className={"chat-msg-time " + classes.them}>
                          {<Moment format="HH:mm" date={message.timestamp}/>}
                        </span>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid
                                        className="chat-msg-sender me"
                                        item
                                        xs={12}
                                        lg={12}
                                    >
                                        <Grid
                                            item
                                        >
                                            <Typography
                                                className="chat-msg-content second-color"
                                                variant="body2"
                                            >
                          <span
                              dangerouslySetInnerHTML={{
                                  __html: message.message,
                              }}
                          />
                                            </Typography>
                                            <span className={"chat-msg-time " + classes.me}>
                          <Moment format="HH:mm" date={message.timestamp}/>
                        </span>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        );
                    })}
                </div>
                <Paper
                    className={classes.submit}
                    component="form"
                    onSubmit={handleSubmit}
                    id="form"
                    key={key}
                >
                    <InputBase
                        name="chatMessage"
                        variant="outlined"
                        autoComplete="off"
                        className="chat-control"
                        placeholder={chatPlaceholder}
                    />
                    <IconButton color="primary" type="submit">
                        <SendIcon/>
                    </IconButton>
                </Paper>
            </div>
        </>
    );
};

export default Chat;
