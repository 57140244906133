import React, {useEffect, useRef, useState} from "react";
import Video, {
    createLocalAudioTrack,
    createLocalVideoTrack,
    LocalTrack,
    LocalTrackOptions,
    LocalVideoTrack,
    Room as RoomType,
} from "twilio-video";
import {fetchToken, joinRoom} from "../../api";
import english from "../../i18n/en.json";
import Chat from "../../components/Chat";
import ScreenRoom from "./screens/Home";
import audio from "../../components/audio";
import RoomDescription from "../RoomDescription";
import {v4 as uuidv4} from "uuid";
import {translateError} from "../../errors";
import {DEVICE_KEY} from "../DeviceSelector";
import {getCookie} from "../../util/utils";

let screenTrack: any = null;

interface I18N {
    [key: string]: any;
}

function Room() {
    const [i18n, setI18n] = useState<I18N>(english);
    const [sharing, setSharing] = useState(false);
    const [noSharing, setNoSharing] = useState(false);
    const [participantSharing, setParticipantSharing] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const stepsMessages = [i18n.joinRoom, i18n.exit];
    const [room, setRoom] = useState<RoomType>();
    const [errorMsg, setErrorMsg] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [participantJoined, setParticipantJoined] = useState<boolean>(false);
    const [connected, setConnected] = useState<boolean>(false);
    const [headerText, setHeaderText] = useState<string>("");
    const remoteVideo = useRef<HTMLMediaElement>();
    const localVideo = useRef<HTMLMediaElement>();
    const [endTime, setEndTime] = useState<Date>(new Date(2030, 11, 20));
    const [startTime, setStartTime] = useState<Date>(new Date(2020, 0, 1));
    const [invitedParticipants, setInvitedParticipants] = useState<string[]>([]);
    const [cameraOn, setCameraOn] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);
    const [token, setToken] = useState<string>("");
    const [roomName, setRoomName] = useState<string>("Room name");
    const [username, setUsername] = useState<string>();
    const [supportNumber, setSupportNumber] = useState<string>("");
    const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has("xid")) {
            const date = new Date();
            const xid = params.get("xid");
            date.setDate(date.getDate() + 1);
            document.cookie = `sid=${xid}; expires=${date.toUTCString()}; path=/`;
            window.location.search = "";
            setUsername(xid as string);

        }
        // Sharing not possible on mobile devices
        const mediaDisplay = (navigator?.mediaDevices as any).getDisplayMedia;
        if (mediaDisplay === undefined) {
            setNoSharing(true);
        }
    }, []);
    const isSafari = () => {
        return navigator.userAgent.toLowerCase().indexOf("safari/") > -1;
    };
    const isEdge = () => {
        return navigator.userAgent.toLowerCase().indexOf("edg") > -1;
    };

    useEffect(() => {
        if (
            participantJoined &&
            // @ts-ignore
            remoteVideo?.current?.scrollIntoView
        ) {
            setTimeout(() => {
                // @ts-ignore
                remoteVideo?.current?.scrollIntoView({
                    block: "end",
                });
            }, 1250);
        }
    }, [participantJoined]);

    useEffect(() => {
        if (room != null) {
            if (isSafari() || isEdge()) {
                // const elem = localVideo.current;
                const elem = document.getElementById(room.localParticipant.identity);

                if (elem) {
                    const localVideoTrack = Array.from(
                        room.localParticipant.videoTracks.values()
                    )[0].track;
                    if (elem && elem.children.length > 0) {
                        elem.removeChild(elem.firstElementChild as HTMLElement);
                    }
                    elem.appendChild(localVideoTrack.attach());
                    elem.setAttribute("playsinline", "true");
                    elem.setAttribute("muted", "true");
                }
            }

            if (room.participants.size > 0) {
                setParticipantJoined(true);
            } else {
                setParticipantJoined(false);
            }

            room.on("participantDisconnected", () => {
                // const elem = remoteVideo.current;
                // while (elem.firstChild) {
                //     elem.removeChild(elem.firstChild);
                // }
                setParticipantJoined(false);
            });

            room.on("participantConnected", () => {
                setParticipantJoined(true);
            });

            // participant started screen sharing
            // setParticipantSharing is not used anywhere,
            // but updating setParticipantSharing causes the component to be rendered again
            room.on("trackPublished", () => {
                setParticipantSharing(true);
            });

            //participant stopped screen sharing
            // setParticipantSharing is not used anywhere,
            // but updating setParticipantSharing causes the component to be rendered again
            room.on("trackUnpublished", () => {
                room.participants.forEach((participant) => {
                    let childElement = document.getElementById(participant.identity);
                    if (childElement) {
                        childElement.setAttribute("controls", "false");
                    }
                });
                setParticipantSharing(false);
            });
        }
    }, [room]);

    useEffect(() => {
        window.addEventListener("beforeunload", function () {
            if (room && room.disconnect) {
                room.disconnect();
            }
        });
    }, [connected, room]);

    useEffect(() => {
        const onMount = async () => {
            const path = window.location.pathname;
            const roomName = path.substring(1);
            if (roomName) {
                setRoomName(roomName);
                const res = (await fetchToken(roomName)) as any;
                setToken(res.token as string);
                setHeaderText(res.roomName);
                setEndTime(new Date(res.endTime));
                setStartTime(new Date(res.startTime));
                setInvitedParticipants(res.participants);
                setSupportNumber(res.phone);
                if (isExpired()) {
                    setErrorMsg(i18n.roomExpired);
                }
            } else {
                setErrorMsg(i18n.roomNotFound as string);
            }
        };
        onMount().catch((e) => {
            const msg = e.message;
            if (msg === "Room expired") {
                setErrorMsg(i18n.expiredRoom as string);
            } else if (msg === "Request failed with status code 404") {
                setErrorMsg(i18n.roomNotFound);
            } else {
                let s = translateError(e);
                if (s === "Unknown error:") {
                    setErrorMsg(s + " " + e.message + " - " + e.name);
                } else {
                    setErrorMsg(s);
                }
            }
        });
    }, []);

    useEffect(() => {
        if (document.cookie) {
            const sid = getCookie("sid");
            setUsername(sid);
        } else {
            setUsername(uuidv4());
        }
    }, [token]);

    const isRoomReady = () => {
        if (startTime) {
            const halfHourBeforeStartTime = new Date(startTime);
            halfHourBeforeStartTime.setMinutes(startTime.getMinutes() - 30);
            return halfHourBeforeStartTime <= new Date();
        }
        return false;
    };

    const isExpired = () => {
        if (endTime) {
            return new Date() > new Date(endTime);
        }
        return false;
    };

    const handleJoinRoom = async (localTracks: LocalTrack[] | undefined) => {
        const ready = isRoomReady();
        if (!ready) {
            setErrorMsg(i18n.roomNotReady as string);
        }
        if (token && ready) {
            try {
                const r = await joinRoom(token, roomName, localTracks);
                setRoom(r);
                setConnected(true);
                setStep(1);
                setLoading(false);
                if (window && window.scrollTo) {
                    window.scrollTo(0, 0);
                }
            } catch (err) {
                setLoading(false);
                const msg = err.message;
                if (msg === "Room expired") {
                    setErrorMsg(i18n.expiredRoom as string);
                } else {
                    const i18nError = translateError(err);
                    if (i18nError === "Unknown error:") {
                        setErrorMsg("Unknown Error: " + i18n.errorOnJoin);
                    } else {
                        setErrorMsg(i18n[i18nError] as string);
                    }
                }
            }
        }
    };
    const cameraPreview = async () => {
        try {
            const vTrack = await createLocalVideoTrack({
                name: "video",
                deviceId: localStorage.getItem(DEVICE_KEY.VIDEO) || undefined,
            });
            const audioTrack = await createLocalAudioTrack({
                name: "audio",
                deviceId: localStorage.getItem(DEVICE_KEY.AUDIO_INPUT) || undefined,
            });
            const localTracks = [vTrack, audioTrack];
            setCameraOn(true);
            setVideoTrack(vTrack);
            return localTracks;
        } catch (e) {
            setCameraOn(false);
            setErrorMsg(i18n.errorOnMedia);
            console.error(e);
        }
    };

    const handleCameraAndRoom = async () => {
        const tracks = await cameraPreview();
        await handleJoinRoom(tracks);
    };
    const onButtonClick = async () => {
        if (!room) {
            setLoading(true);
            if (isRoomReady()) {
                audio.play().catch(); // Safari work-around
                await handleCameraAndRoom();
            } else {
                setErrorMsg(i18n.roomNotReady);
            }
            setLoading(false);
        } else {
            room.disconnect();
            window.location.reload();
        }
    };

    const getRefIfDesktop = (ref: any) => {
        if (window.innerWidth > 1000) {
            return ref;
        }
        return null;
    };

    const getRefIfMobile = (ref: any) => {
        if (window.innerWidth < 1000) {
            return ref;
        }
        return null;
    };

    const isMobile = () => {
        return window.innerWidth < 1000;
    };

    const errorText = () => (
        <div>
            <p className="warning mobile-margin-top">{errorMsg}</p>
        </div>
    );

    const SupportNumber = (phone: string) => {
        let defaultNumber = i18n.defaultPhoneNumber;
        let str = i18n.support.replace("{}", defaultNumber);
        if (phone) {
            str = i18n.support.replace("{}", phone);
        }
        return <b>{str}</b>;
    };

    // show the room Description
    const onShowDescription = () => {
        setShowDescription(!showDescription);
    };

    const onShareScreen = () => {
        if (sharing) {
            stopCapture();
        } else {
            startCapture();
        }
    };

    const startCapture = () => {
        (navigator.mediaDevices as any)
            .getDisplayMedia(displayMediaOptions)
            .then((stream: { getTracks: () => MediaStreamTrack[] }) => {
                setSharing(true);

                screenTrack = new Video.LocalVideoTrack(stream.getTracks()[0], {
                    name: "screenShare",
                } as LocalTrackOptions);
                if (room) {
                    room.localParticipant.videoTracks.forEach((publication) => {
                        publication.unpublish();
                    });

                    room.localParticipant
                        .publishTrack(screenTrack)
                        .then(() => {
                            screenTrack.mediaStreamTrack.onended = () => {
                                stopCapture();
                            };

                            // Get a reference to the parent node
                            let parentDiv = document.getElementById(
                                room.localParticipant.identity
                            )?.parentNode;
                            let childElement = document.getElementById(
                                room.localParticipant.identity
                            );
                            if (childElement) {
                                childElement.setAttribute("hidden", "true");
                            }
                            if (parentDiv) {
                                // Insert new child element before the old one
                                let newElement = parentDiv.insertBefore(
                                    screenTrack.attach(),
                                    childElement
                                );
                                newElement.setAttribute("id", "share");
                            }
                        })
                        .catch((err) => {
                            setSharing(false);
                            console.error("Error: " + err);
                        })
                        .catch((err) => {
                            setSharing(false);
                            console.error("Error: " + err);
                        });
                }
            });
    };

    const stopCapture = () => {
        if (room) {
            room.localParticipant.unpublishTrack(screenTrack);
            screenTrack.stop();
            screenTrack = null;
            setSharing(false);
            let childElement = document.getElementById(
                room.localParticipant.identity
            );
            if (childElement) {
                childElement.removeAttribute("hidden");
            }
            let shareElement = document.getElementById("share");
            if (shareElement) {
                let parentDiv = document.getElementById(room.localParticipant.identity)
                    ?.parentNode;
                if (parentDiv) {
                    parentDiv.removeChild(shareElement);
                }
            }
            //Return to the original track
            room.localParticipant
                .publishTrack(videoTrack as LocalTrack)
                .then()
                .catch((err) => {
                    console.error("Error: " + err);
                });
        }
    };

    const displayMediaOptions = {
        video: {
            cursor: "always",
        },
        audio: true,
    };

    return (
        <>
            <ScreenRoom
                expired={isExpired()}
                isMobile={isMobile}
                helper={supportNumber && SupportNumber(supportNumber)}
                headerText={headerText}
                username={username}
                endTime={endTime}
                startTime={startTime}
                onButtonClick={onButtonClick}
                loading={loading}
                stepsMessages={stepsMessages}
                step={step}
                room={room}
                participantJoined={participantJoined}
                errorMsg={errorMsg}
                errorText={errorText}
                cameraOn={cameraOn}
                getRefIfDesktop={getRefIfDesktop}
                getRefIfMobile={getRefIfMobile}
                localVideo={localVideo}
                remoteVideo={remoteVideo}
                i18ntext={i18n}
                setI18nText={setI18n}
                Chat={Chat}
                onShareScreen={onShareScreen}
                sharing={sharing}
                noSharing={noSharing}
                onShowDescription={onShowDescription}
            />
            <RoomDescription
                open={showDescription}
                i18nText={i18n}
                onShowDescription={onShowDescription}
                description={invitedParticipants}
            />
        </>
    );
}

export default Room;
