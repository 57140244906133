import { TwilioError } from "twilio-video";

// https://www.twilio.com/docs/api/errors
export const translateError = (err: TwilioError): string => {
  switch (err.code) {
    case 53105:
      return "roomFull";
    case 53104:
      return "errorOnJoin";
    case 53106:
      return "roomNotFound";
    case 53113:
      return "roomNotReady";
    default:
      return "Unknown error:";
  }
};
