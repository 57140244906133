import React, {useEffect, useState} from "react";
import bellAudio from '../assets/bell.mp3'
import bellIcon from '../assets/alarm-outline.svg'
import audio from './audio';

type BellProps = {
    ringTime: Date
}
const Bell: React.FC<BellProps> = ({ringTime}) => {

    const [played, setPlayed] = useState(false)
    const [animate, setAnimate] = useState(false)
    const [currentTime] = useState(new Date())


    useEffect(() => {
        const playSound = (audioSource: HTMLAudioElement) => {
            if (!played && audioSource !== null) {
                audioSource.src = bellAudio;
                audioSource.volume = 0.03;
                audioSource.loop = false;
                setPlayed(true)
                audioSource.play().then(() =>{
                    audioSource.volume = 0.03
                    setAnimate(true)
                    setTimeout(() => {
                        setAnimate(false)
                        audioSource.pause()
                    }, 4000)
                })
                    .catch(console.error)
            }
        }
        if (ringTime) {
            const timeUntilRingMs = ringTime.getTime() - currentTime.getTime();
            // setTimeout has maximum limit of 2^31 - 1
            const MAX_SIGNED_INT = Math.pow(2, 31) - 1;
            if (timeUntilRingMs < MAX_SIGNED_INT) {
                setTimeout(() => {playSound(audio)}, timeUntilRingMs)
            }
        }
    },[currentTime, played, ringTime])

    return <span>
        <img src={bellIcon} alt="Bell icon" className={animate ? 'bell bellActive' : 'bell'}/>
    </span>
}
export default Bell
