import {createTheme} from "@material-ui/core/styles"

const theme = createTheme({
    palette: {
        primary: {
            main: "#06c2ff",
        },
        background: {
            default: "#fff",
            secondary: 'gray'
        },
    },
    color: "#fff",
    //*****
    overrides: {
        MuiButton: {
            containedPrimary: {
                '&:hover':{
                    backgroundColor:'#33abd2'       //50% salured from original
                }
            },
            containedSizeLarge:{
                color: "#fff"
            }
        },
        MuiTypography: {
            root: {
                color: "#fff",
            }
        },
        MuiListItemText: {
           primary:{
                color:"black !important"
            }
        }
    }
})

export default theme
