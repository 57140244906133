import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    overlay: {
        zIndex: 9,
        opacity: 0.5,
        position: "fixed",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.background.secondary
    }
}));

const Initial = () => {
    const classes = useStyles();

    return (
        <div className={classes.overlay}/>
    );
};

export default Initial;