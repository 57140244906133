import {DEVICE_KEY} from "./DeviceSelector";

const setupAudio = () => {
    const audioConf = new Audio()
    audioConf.loop = false;
    audioConf.volume = 0.05;
    const sink = localStorage.getItem(DEVICE_KEY.AUDIO_OUTPUT)
    // @ts-ignore
    if (sink && audioConf.setSinkId){
        // @ts-ignore
        audioConf.setSinkId(sink)
    }
    audioConf.play().catch(e => console.error("Not allowed to play: ", e));
    return audioConf
}



export default setupAudio();
