export interface CompanyDetails {
  title: String;
  username: String;
  desc: String;
}

export const isLessThanMinuteAway = (
  localDateTime: Date,
  expirationDateTime: Date
) => {
  const second = 1000; // in Millis
  const minute = 60 * second;
  // when time is 15:59 and expiration time is 16:00 - return true
  if (isRoomExpired(localDateTime, expirationDateTime)) {
    return false;
  }
  return localDateTime.getTime() > expirationDateTime.getTime() - minute;
};
export const isRoomExpired = (
  localDateTime: Date,
  expirationDateTime: Date
) => {
  return localDateTime.getTime() > expirationDateTime.getTime();
};

export const toFinnishLocaleDate = (localDateTime: Date) => {
  return localDateTime.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const subtractMinuteFromTime = (date: Date) => {
  const cp = new Date(date);
  cp.setMinutes(date.getMinutes() - 1);
  return cp;
};

export const parseParticipants = (participantsResponse: any) => {
  if (Array.isArray(participantsResponse)) {
    return participantsResponse.map((entry) => {
      if (entry && typeof entry === "string") {
        const result = entry.split(" && ");
        const companyDetails: CompanyDetails = {
          title: "",
          username: "",
          desc: "",
        };
        result.forEach((value, index) => {
          if (value !== null) {
            switch (index) {
              case 0:
                companyDetails.title = value;
                break;
              case 1:
                companyDetails.username = value;
                break;
              case 2:
                companyDetails.desc = value;
                break;
              default:
                return;
            }
          }
        });
        return companyDetails;
      } else {
        return "";
      }
    });
  }
};
const supportedLanguages = (lang: string) => {
  const langs = ["fi", "en", "sv"];
  if (langs.includes(lang)) {
    return lang;
  }
  return null;
};

export const getPreferredLang = () => {
  const queryparams = new URLSearchParams(window.location.search);

  const lang = queryparams.get("lang");
  if (lang) {
    const allowedLang = supportedLanguages(lang);
    if (allowedLang) {
      return allowedLang;
    }
  } else {
    const navLang: string = navigator.language;
    const allowedLang = supportedLanguages(navLang.substring(0, 2));
    if (allowedLang) {
      return allowedLang;
    }
  }
  return "en";
};

export function setAllAudioToSink(sinkId: string){
  document.querySelectorAll("audio").forEach((elem) => {
    /* Chrome & Edge only feature
    * https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId
    *
    * */
    // @ts-ignore
    if (elem && elem.setSinkId) {
      // @ts-ignore
      elem.setSinkId(sinkId)
    }
  })
}


export const getCookie = (name: string) => {
  let cookie = {};
  document.cookie.split(';').forEach(function(el) {
    let [k,v] = el.split('=');
    // @ts-ignore
    cookie[k.trim()] = v;
  })
  // @ts-ignore
  return cookie[name];
}