const VIDEO = "videoinput";
const AUDIO_INPUT = "audioinput";
const AUDIO_OUTPUT = "audiooutput";

export const listAudioInputDevices = (): Promise<MediaDeviceInfo[]> => {
  return navigator.mediaDevices
    .getUserMedia({ audio: true, video: true })
    .then(() => listDevices())
    .then((devices) => devices.filter((d) => d.kind === AUDIO_INPUT));
};

export const listVideoDevices = (): Promise<MediaDeviceInfo[]> => {
  return navigator.mediaDevices
    .getUserMedia({ video: true })
    .then(listDevices)
    .then((devices) => devices.filter((d) => d.kind === VIDEO));
};

export const listAudioOutputDevices = (): Promise<MediaDeviceInfo[]> => {
  return navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(listDevices)
      .then((devices) => devices.filter((d) => d.kind === AUDIO_OUTPUT));
};

const listDevices = (): Promise<MediaDeviceInfo[]> => {
  return navigator.mediaDevices.enumerateDevices();
};
