import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { parseParticipants } from "../util/utils";
import { CompanyDetails } from "../types";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#06c2ff",
    color: "white",
  },
  desc: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  btn: {
    color: "white",
    fontSize: 20,
  },
});

export interface RoomDescriptionProps {
  open: boolean;
  description: any;
  onShowDescription: () => void;
  i18nText: any;
}

const RoomDescription: FunctionComponent<RoomDescriptionProps> = ({
  open,
  description,
  onShowDescription,
  i18nText,
}) => {
  let participants = parseParticipants(description);
  const classes = useStyles();
  const [scroll] = React.useState<DialogProps["scroll"]>("paper");
  const descriptionElementRef = React.useRef<HTMLElement>(null);

  return (
    <Dialog
      open={open}
      scroll={scroll}
      aria-describedby={"scroll-description"}
      onClose={onShowDescription}
      aria-labelledby="dialog-title"
    >
      <DialogTitle
        className={classes.root}
        id="simple-dialog-title"
        disableTypography
      >
        {i18nText.incomingParticipants}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id={"scroll-description"}
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <div>
            {participants &&
              (participants as CompanyDetails[]).map((part) => (
                <div
                  key={part.title as string}
                  style={{ padding: "1rem", marginTop: "0.5rem" }}
                >
                  <div>
                    <b>{part.title}</b>
                  </div>
                  <div>{part.username}</div>
                  <p>
                    <i>{part.desc}</i>
                  </p>
                </div>
              ))}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button className={classes.btn} onClick={onShowDescription}>
          {i18nText.exit}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoomDescription;
